import React,{useEffect} from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
//import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
//import SettingsNotification from 'src/components/SettingsNotification';
import InternetConnection from 'src/components/InternetConnection';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { FeeSetupProvider } from 'src/contexts/FeeSetupContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { createCustomTheme} from 'src/theme5';
import routes, { renderRoutes } from 'src/routes';
import { StreamProvider } from 'src/contexts/StreamContext';

import { ImportCompanySetupProvider } from 'src/contexts/ImportForCompanyContext';
import {  ThemeProvider as NewThemeProvider  } from '@mui/material/styles';
import { ModalExecutionContextProvider } from 'src/contexts/ModalExecution';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();
console.log(navigator.onLine);
const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const newTheme =createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  })
  useEffect(() => {
    // Disable Right Click
    if (process.env.REACT_APP_ENV !== 'test'){

    document.addEventListener("contextmenu", (event) => event.preventDefault());
  
    // Disable F12 and other DevTools shortcuts
    document.addEventListener("keydown", (event) => {
      if (
        event.key === "F12" || 
        (event.ctrlKey && event.shiftKey && ["I", "J", "C"].includes(event.key)) ||
        (event.ctrlKey && event.key === "U")
      ) {
        event.preventDefault();
      }
    });
  
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
      document.removeEventListener("keydown", (event) => {
        if (
          event.key === "F12" ||
          (event.ctrlKey && event.shiftKey && ["I", "J", "C"].includes(event.key)) ||
          (event.ctrlKey && event.key === "U")
        ) {
          event.preventDefault();
        }
      });
    };
  }
  }, []);

  useEffect(() => {
    // 1. Detect DevTools on Load
    if (process.env.REACT_APP_ENV !== 'test'){
    const detectDevTools = () => {
      const element = new Image();
      Object.defineProperty(element, "id", {
        get: function () {
          alert("Developer tools detected ! Closing the page.");
          window.location.href = "about:blank"; 
        },
      });
      console.log(element);
    };
  

    // 3. Debugger Trap
    const disableDevTools = () => {
      setInterval(() => {
        debugger;
      }, 100);
    };
  

  
    // Run all methods
    const interval1 = setInterval(detectDevTools, 1000);
    disableDevTools();
  
    return () => {
      clearInterval(interval1);
    };
  } 
  }, []);
  return (
    <NewThemeProvider theme={newTheme}>
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense={false} maxSnack={1}>
            <Router history={history}>
              <AuthProvider>
                <ImportCompanySetupProvider>
                <ModalExecutionContextProvider>
                  <FeeSetupProvider>
                    <StreamProvider>
                     
                          <GlobalStyles />
                          <ScrollReset />
                          <GoogleAnalytics />
                          {/* <CookiesNotification />
                <SettingsNotification /> */}
                          <InternetConnection />
                          {renderRoutes(routes)}
                       
                    </StreamProvider>
                  </FeeSetupProvider>
                 </ModalExecutionContextProvider>
                </ImportCompanySetupProvider>
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
    </NewThemeProvider>
  );
};

export default App;
