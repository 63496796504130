import getInitials from 'src/utils/getInitials';
import getName from 'src/utils/getName';
import ChangePassword from 'src/views/auth/LoginView/Change_Password';

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Card,
  ButtonBase,
  Hidden,
  CircularProgress,
  IconButton,
  Grid,
  CardHeader,
  SvgIcon,
  Dialog,
  Typography,
  makeStyles
} from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { ChevronDown, CheckCircle ,Lock } from 'react-feather';
import client from 'src/utils/GQLSettingsClient';
import useAuth from 'src/hooks/useAuth';
import useStream from 'src/hooks/useStream';
import fetchDocumentImage from 'src/components/get_document_image';
import client1 from 'src/utils/GQLExamClient';
import moment from 'moment';
import { Icon } from '@iconify/react';
import LockIcon from '@mui/icons-material/Lock';
const useStyles = makeStyles(theme => ({
  avatar: {
    //   height: 35,
    //   width: 35,
    marginRight: theme.spacing(1),
    display:'flex',
    justifyContent:'center',
    alignContent:'center',
    alignItems:'center'
  },
  popover: {
    width: 200
  },
  menuItem: {
    color: theme.palette.background.dark,
    borderRadius: '4px',
    padding: '12px 24px',
    margin: '6px 0'
  },
  item: {
    margin: 0,
    padding: 0
    // borderRadius: '12px',
    // '&:hover': {
    //   // backgroundColor: theme.palette.background.dark
    // }
    // alignItems: 'center'
  }
}));
const noPointer = { cursor: 'pointer' };

const Account = load => {
  const { user } = useAuth();

  const { setStream, stream } = useStream();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  console.log('streamselected', stream);
  const handleOpen = () => {
    setOpen(true);
  };

  function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const handleClose = () => {
    setOpen(false);
  };
  const [settings, setSettings] = useState([{}, {}]);

  const [open, setOpen1] = useState(false);
  let folderName = user['custom:institution_id'];

  const handleClickOpen = async data => {
    try {
      handleClose();

      await setStream('exam', 'schedule', data._id);
    } catch {
      console.log('err');
    } finally {
      // window.location.reload();
    }
  };

  function search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].applicantId === nameKey) {
        return myArray[i];
      }
    }
  }
  const [ImageList, setImageList] = useState([]);
  const s3 = new AWS.S3();

  const getStreams = useCallback(async () => {
    try {
      const getstudents = gql`
               query MyQuery( $exam_type: String!) {
                 get_exam_schedules(
                   exam_type: $exam_type
                 ) {
                   _id
                   status
                   exam_mode
                   end_date
                   academic_year
                   exam_name
                   institution_id
                   exam_type
                   start_date
                   term_array
                   result_entry
                   stream_array
                   exam_category
                 }
               }
             `;

      const { data } = await client1
        .query({
          query: getstudents,
          variables: {

            exam_type:'external',
          },
          fetchPolicy: 'network-only'
        })
        .then(res => {
          console.log(res);

          return res;
        });

      if (isMountedRef.current) {
        setSettings(data.get_exam_schedules);
        const value = data.get_exam_schedules.find(
          x => x._id == stream.session_value
        );
        setLoading(false);
        console.log(data.get_exam_schedules);

        // data.get_exam_schedules.map(async customer => {
        //   if (!(customer.AddOutlinedIcon_id === null || undefined)) {
        //     let bucketName = 'erpinstitution';
        //     var photoKey = customer.institution.logo_url;
        //     const img = await fetchDocumentImage('logo', photoKey);

        //     if (img === undefined) {
        //       setImageList(oldArray => [
        //         ...oldArray,
        //         {
        //           applicantId: customer._id,
        //           Image: null
        //         }
        //       ]);
        //     } else {
        //       var base64Flag = `data:${img.contentType};base64,`;
        //       var imgData = _arrayBufferToBase64(img.Body);

        //       setImageList(oldArray => [
        //         ...oldArray,
        //         {
        //           applicantId: customer._id,
        //           Image:img
        //         }
        //       ]);
        //     }
        //   } else {
        //     setImageList(oldArray => [
        //       ...oldArray,
        //       {
        //         applicantId: customer._id,
        //         Image: null
        //       }
        //     ]);
        //   }
        // });
      }
    } catch (err) {
      console.error('strream',err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getStreams();
    getCustomersImage(encodeURIComponent(folderName) + '/' + encodeURIComponent('logo') + '/' + user['custom:institution_id']);

  }, [getStreams]);

  // console.log('user', stream.institution);
console.log(stream);

let img;
let logo;
const [image, setImage] = useState(null);
const arrayBufferToBase64 = buffer => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach(b => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};
async function get_logo(image_path) {
  const img = await fetchDocumentImage('logo', image_path);
  return img;
}

const getCustomersImage = useCallback(
  async data => {
    console.log(data)
    try {
      var image_path = data;

      const i = await fetchDocumentImage('logo', image_path);
      setImage(i);
      img = await get_logo(image_path);
      console.log(img)
      if (img === undefined || img === null) {
        logo = null;
        setImage(null);

      } else {
        var base64Flag = `data:${img.contentType};base64,`;
        var imgData = arrayBufferToBase64(img.Body);
        logo = base64Flag + imgData;
        console.log(logo)
       
        // setImage(logo);
      }
    } catch (err) {
      setImage(null);
      console.error(err);
    }
  },
  [isMountedRef]
);
console.log(image);
  return (
    <>
      <CardHeader
        className={classes.item}
        style={noPointer}
        title={
          <Box
            display="flex"
            // alignItems="left"
            // component={ButtonBase}
            onClick={handleOpen}
            ref={ref}
            // className={classes.item}
          >
            {stream ? (
<Avatar 
  className={classes.avatar} 
  src={image}
  style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center' 
  }}

  
/>
  
           ) : (
              <Skeleton variant="circle" width={40} height={40} />
            )}
            <Hidden smDown>
              <Box ml={0.5}>
                <Box display="flex">
                  {stream ? (
                    <Typography variant="h6" textAlign="left" color="white">
                      {stream.exam_name}{' '}{`( ${stream.academic_year} )`}
                    </Typography>
                  ) : (
                    <Skeleton width={120} />
                  )}
                  <Box mr={1} />

                  <SvgIcon cursor="pointer">
                    <ChevronDown strokeWidth="2px" size="20px" color="white" />
                  </SvgIcon>
                </Box>
                <Box display="flex" mt={-0.2}>
                  {stream ? (
                    <Typography
                      variant="body2"
                      textAlign="left"
                      // color="inherit"
                      color="white"
                    >
                      {stream.exam_type ? getName(stream.exam_type) : ''} - {' '}
                      {stream.exam_mode ? getName(stream.exam_mode) : ''} -{' '}
                       {`${moment(stream.start_date).format(
                                                        'DD MMM'
                                                      )} - ${moment(stream.end_date).format('DD MMM')}`} 
                    </Typography>
                  ) : (
                    <Skeleton width={80} />
                  )}
                </Box>
              </Box>
            </Hidden>
          </Box>
        }
      ></CardHeader>

      <Menu
        onClose={handleClose}
        keepMounted
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '250px',
            padding: '10px 18px 8px 18px',
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
              borderRadius: '8px',
              backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },
            maxHeight: '600px',
            overflow: 'auto',

            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.2,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
       {[...settings]
  .sort((a, b) => (a.exam_name === stream.exam_name ? -1 : b.exam_name === stream.exam_name ? 1 : 0))
  .map(data => {
    let img;
    if (search(data._id, ImageList)) {
      img = search(data._id, ImageList).Image;
    }
    return (
      <MenuItem
        key={data._id} // Ensure unique keys
        className={classes.menuItem}
        onClick={e => data.status === "active" && handleClickOpen(data)}
      >
        <Box display="flex" width="100%" onClick={handleOpen}>
          <Box display="flex">
            <Hidden xsDown>
              <Box ml={0.5}>
                <Box display="flex">
                  <Typography variant="h6" textAlign="left" color="textPrimary">
                    {data.exam_name} {' - '}{`( ${data.academic_year} )`}
                  </Typography>
                  <Box mr={1} />
                </Box>
                <Box display="flex" mt={-0.5}>
                  <Typography variant="body2" textAlign="left" color="textSecondary">
                    {data.exam_type ? getName(data.exam_type) : ''} -{' '}
                    {data.exam_mode ? getName(data.exam_mode) : ''} -{' '}
                    {`${moment(data.start_date).format('DD MMM')} - ${moment(data.end_date).format('DD MMM')}`}
                  </Typography>
                </Box>
              </Box>
            </Hidden>
          </Box>
          <Box flexGrow={1} />
          <Box mr={1} />
          <Box display={'flex'}>
            {data.exam_name === stream.exam_name && (
              <CheckCircle size="20px" strokeWidth="3px" color="#002F6D" />
            )}
            {data.status !== "active" && <Box mr={1} />}
            {data.status !== "active" && (
              <Lock size="20px" strokeWidth="3px" color="#9e9e9e" />
            )}
          </Box>
        </Box>
      </MenuItem>
    );
  })}

      </Menu>
    </>
  );
};

export default Account;
